<template>
  <div
    v-if="!!bannerList.length"
    class="banner-swiper"
  >
    <swiper
      :options="gameSwiperOption"
    >
      <swiper-slide
        v-for="(item, index) in bannerList"
        :key="index"
      >
        <div
          @click="onImgClickHander(item)"
          class="banner-swiper-slide"
        >
          <img
            :src="item.fillPicUrl"
            alt=""
            class="banner-swiper-slide-img"
          >
        </div>
      </swiper-slide>
      <div
        slot="pagination"
        class="swiper-pagination"
      />
    </swiper>
  </div>
</template>

<script>
import 'swiper/dist/css/swiper.css';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import { getNewGameBanner } from '@/api/newgame';
import { openView } from '@/jsbridge';

export default {
  name: 'NewGameBanner',
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    gameCode: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      gameSwiperOption: {
        spaceBetween: 20,
        loop: true,
        autoplay: { delay: 2500, disableOnInteraction: false },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
      },
      bannerList: [],
    };
  },
  mounted() {
    getNewGameBanner(this.gameCode).then((res) => {
      this.bannerList = res.banner;
    });
  },
  methods: {
    onImgClickHander(bannerInfo) {
      const url = bannerInfo.jumpUrl || bannerInfo.post.postInfo.extent.postDetailUrl;
      openView({
        options: url,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.banner-swiper{
  height: 84px;
  border-radius: 8px;
  margin:8px 16px;
  overflow: hidden;
  box-sizing: border-box;
  &-slide{
    &-img{
      width: 100%;
      padding-right: 16px;
      height: 84px;
      object-fit: cover;
      border-radius: 6px;
      margin-right: 16px;
    }
  }
}
.swiper-pagination {
  bottom: 12px;
  line-height: 5px;

  ::v-deep.swiper-pagination-bullet {
    width: 5px;
    height: 5px;
    opacity: 1;
    border-radius: 100%;
    background: #ccc;

    padding: 0;
  }

  ::v-deep.swiper-pagination-bullet-active {
    opacity: 1;
    background: #ececec;

  }
}
</style>
